<template>
	<div id="tools" v-if="$store.state.offre_ready">
		<div class="topbar">
			<router-link class="button_box" to="/"><i class="far fa-arrow-left"></i> Go to dashboard</router-link>
			<div class="button_box" @click="handleActions('delete')" v-if="$store.state.user.role.administrator">
				<i class="far fa-trash"></i> Delete this offer
			</div>
			<div class="button_box" @click="handleActions('mask')"><i class="far fa-eye-slash"></i> Hide this offer (permanent)</div>
			<div class="button_box" @click="handleActions('onlypdf')" v-if="$store.state.offre_active.statut == 'draft'">
				<i class="far fa-file-pdf"></i> Validate & generate PDF
			</div>
		</div>

		<div class="box" v-if="$store.state.offre_active.statut == 'send'">
			<select @change="handleStatut" v-model="$store.state.offre_active.statut">
				<option value="send">Issued</option>
				<option value="accept">Accepted by customer</option>
				<option value="decline">Declined by customer</option>
				<option value="draft">Rollback to Draft</option>
			</select>
		</div>

		<div class="box capacities" v-if="$store.state.offre_active.statut == 'draft'">
			<h4 @click="handleBox($event)">Use second capacities table <i class="far fa-angle-right"></i></h4>
			<div class="content">
				<p>By switching on, you can use a second table of capabilities on description page</p>
				<van-switch v-model="capacities2" active-color="#f18b00" size="20" />
			</div>
		</div>
		<div class="box currency" v-if="$store.state.offre_active.statut == 'draft'">
			<h4 @click="handleBox($event)">Currency <i class="far fa-angle-right"></i></h4>
			<div class="content">
				<p>
					Apply a different currency to this offer. <br />EUR by default. <br />
					<b
						>Transfer and public prices are in €UR, communicated to you by AMB Picot’s authorised personel. In case of doubt, please
						contact us.</b
					>
				</p>
				<select v-model="$store.state.offre_active.devise" @change="update_currencies">
					<option value="EUR">Euro</option>
					<option value="USD">US Dollars</option>
					<option value="CHF">Swiss Franc</option>
					<option value="CAD">Canadian Dollar</option>
					<option value="AUD">Australian Dollar</option>
					<option value="PLN">Polish Zloty</option>
					<option value="GBP">British Pound Sterling</option>
					<option value="JPY">Japanese Yen</option>
					<option value="DKK">Danish Krone</option>
					<option value="SEK">Swedish Krona</option>
					<option value="THB">Thai Baht</option>
					<option value="NZD">New Zealand Dollar</option>
				</select>
				<div class="daily_change_value">Daily change rate : {{ $store.state.currencies[$store.state.offre_active.devise] }}</div>

				<div class="custom_change">
					<label>Apply custom change rate </label>
					<input
						type="number"
						step="0.001"
						min="0"
						:value="$store.state.offre_active.taux_de_change_perso"
						@change="updateTauxDeChangePerso($event)"
					/>
				</div>
			</div>
		</div>

		<div class="box options">
			<h4 @click="handleBox($event)">Custom options <i class="far fa-angle-right"></i></h4>
			<div class="content">
				<div class="options_existantes">
					<div class="option" v-for="(option, index) in cost_options" :key="`cost_option_${index}`">
						<label>{{ option.label }}</label>
						<money-format
							:value="option.price"
							:currency-code="$store.state.offre_active.devise"
							:subunits-value="false"
							:hide-subunits="false"
							:locale="$store.state.offre_active.langue"
						>
						</money-format>
						<div class="remove" @click="removeOption(index)">
							<i class="fas fa-minus"></i>
						</div>
					</div>
				</div>
				<form @submit.prevent="handleActionsCost()">
					<div class="form-group">
						<label for="label">Label</label>
						<input type="text" id="label" placeholder="My option" v-model="new_option.label" />
					</div>
					<div class="form-group">
						<label for="price">Price</label>
						<input type="number" id="price" placeholder="0.00" v-model.number="new_option.price" />
					</div>
					<button type="submit">Add option</button>
				</form>
			</div>
		</div>

		<div class="box pages_order" @end="masterOrder()" v-if="$store.state.offre_active.statut == 'draft'">
			<h4 @click="handleBox($event)">Pages order <i class="far fa-angle-right"></i></h4>
			<div class="content">
				<draggable v-model="pages">
					<transition-group>
						<a :href="`#${element.id}`" v-for="element in pages" class="page-tuile" :key="element.id">
							{{ element.name }}
							<van-switch v-model="element.visible" active-color="#f18b00" size="12" @change="console()" />
						</a>
					</transition-group>
				</draggable>
			</div>
		</div>
	</div>
</template>

<script>
import Airtable from "airtable";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import vSelect from "vue-select";
import Axios from "axios";
import draggable from "vuedraggable";
import { Dialog } from "vant";
import { mapActions, mapGetters } from "vuex";
import MoneyFormat from "vue-money-format";

const base = new Airtable({
	apiKey: "patbf88vmcP5sIIit.ee71112a4ec13dfd6d6e7072aedf4fe93d4a1a67c5f56495c75a4c178ef61a90",
}).base("appsk0eykwa6zk25e");
export default {
	name: "PageTools",
	components: {
		vSelect,
		draggable,
		Dialog,
		MoneyFormat,
	},
	data() {
		return {
			offer_id: this.$route.params.id,
			images_array: {},
			page1_ready: false,
			page2_ready: false,
			page3_ready: false,
			page4_ready: false,
			page5_ready: false,
			page6_ready: false,
			page7_ready: false,
			page8_ready: false,
			page9_ready: false,
			page10_ready: false,
			page11_ready: false,
			page12_ready: false,
			pages: [],
			pages_ready: false,
			new_option: {
				price: 0.0,
				label: "My option",
			},
		};
	},
	computed: {
		cost_options() {
			return this.$store.state.offre_active.extra_options_treated;
		},
		cout_transport: {
			get() {
				return this.$store.state.offre_active.cout_transport;
			},
			set(newValue) {
				this.$store.commit("updateCoutTransport", newValue);
			},
		},
		capacities2: {
			get() {
				return this.$store.state.capacities2;
			},
			set(newValue) {
				this.$store.commit("setCapacities2", newValue);
			},
		},
	},
	methods: {
		removeOption(index) {
			this.removeExtraOption(index);
			this.new_option = {
				price: 0.0,
				label: "My option",
			};
		},
		...mapActions({
			updateTauxDeChangePerso: "updateTauxDeChangePerso",
			addOptions: "addOptions",
			refresh_total: "refresh_total",
			removeExtraOption: "removeExtraOption",
		}),
		handleActionsCost() {
			this.addOptions(this.new_option)
				.then((res) => {
					this.refresh_total();
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => {
					this.new_option = {
						price: 0.0,
						label: "My option",
					};
				});
		},
		console() {
			this.$store.commit("resetOrder");
			let temp = {}; // ordre
			let count = 0;
			this.pages.forEach((el, index) => {
				let obj = {}; // Ordre
				if (el.visible) {
					count++;
				}
				obj[el.id] = {
					ordre: index,
					visible: el.visible,
					count: count,
				};
				temp = Object.assign(temp, obj);
			});
			this.$store.commit("updateOrdrePage", temp);
			this.updateOrderPages();
		},
		masterOrder() {
			this.$store.commit("resetOrder");
			let temp = {}; // ordre
			let count = 0;
			this.pages.forEach((el, index) => {
				let obj = {}; // Ordre
				if (el.visible) {
					count++;
				}
				obj[el.id] = {
					ordre: index,
					visible: el.visible,
					count: count,
				};
				temp = Object.assign(temp, obj);
			});
			this.$store.commit("updateOrdrePage", temp);
			this.updateOrderPages();
		},
		updateOrderPages() {
			base("offres").update(
				[
					{
						id: this.offer_id,
						fields: {
							ordre_des_pages: JSON.stringify(this.pages),
						},
					},
				],
				(err) => {
					if (err) {
						console.error(err);
						return;
					}
				}
			);
		},
		showAlert() {
			Dialog.alert({
				title: "Sorry",
				message: "You cannot access this functionnality yet",
				confirmButtonText: "Understood",
			});
		},
		handleActions(desired_action) {
			if (desired_action == "onlypdf") {
				this.createPDF();
			} else if (desired_action == "delete") {
				this.deleteOffre();
			} else if (desired_action == "mask") {
				this.maskOffer();
			}
		},
		handleStatut(e) {
			let desired_statut = e.target.value;
			let fields = {};
			this.$store.commit("setFreeze", {
				affichage: true,
				texte: "Updating statut.<br /><b>Please wait.</b>",
			});

			base("offres").update(
				[
					{
						id: this.offer_id,
						fields: {
							statut: desired_statut,
						},
					},
				],
				(err, deletedRecords) => {
					if (err) {
						console.error(err);
						return;
					}
					this.$store.commit("setFreeze", {
						affichage: false,
						texte: "",
					});
					this.$store.commit("statut_offre_active", {
						statut: desired_statut,
					});
					this.$store.dispatch("listeOffres");
				}
			);
		},
		verifGeneration() {
			if (
				this.page1_ready &&
				this.page2_ready &&
				this.page3_ready &&
				this.page4_ready &&
				this.page5_ready &&
				this.page6_ready &&
				this.page7_ready &&
				this.page8_ready &&
				this.page9_ready &&
				this.page10_ready &&
				this.page11_ready &&
				this.page12_ready
			) {
				this.downloadPDF();
			}
		},
		dataURItoBlob(dataURI) {
			// convert base64/URLEncoded data component to raw binary data held in a string
			var byteString;
			if (dataURI.split(",")[0].indexOf("base64") >= 0) byteString = atob(dataURI.split(",")[1]);
			else byteString = unescape(dataURI.split(",")[1]);

			// separate out the mime component
			var mimeString = dataURI
				.split(",")[0]
				.split(":")[1]
				.split(";")[0];

			// write the bytes of the string to a typed array
			var ia = new Uint8Array(byteString.length);
			for (var i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i);
			}

			return new Blob([ia], { type: mimeString });
		},
		downloadPDF() {
			let doc = new jsPDF({
				orientation: "p",
				unit: "mm",
				format: "a4",
				compressPdf: true,
				putOnlyUsedFonts: true,
			});
			var width = doc.internal.pageSize.getWidth();
			if (this.$store.state.ordre_des_pages.page1.visible) {
				doc.addImage(this.images_array.page1, "JPEG", 0, 0, 210, 297);
				doc.addPage();
			}
			if (this.$store.state.ordre_des_pages.page2.visible) {
				doc.addImage(this.images_array.page2, "JPEG", 0, 0, 210, 297);
				doc.addPage();
			}
			if (this.$store.state.ordre_des_pages.page3.visible) {
				doc.addImage(this.images_array.page3, "JPEG", 0, 0, 210, 297);
				doc.addPage();
			}
			if (this.$store.state.ordre_des_pages.page4.visible) {
				doc.addImage(this.images_array.page4, "JPEG", 0, 0, 210, 297);
				doc.addPage();
			}
			if (this.$store.state.ordre_des_pages.page5.visible) {
				doc.addImage(this.images_array.page5, "JPEG", 0, 0, 210, 297);
				doc.addPage();
			}
			if (this.$store.state.ordre_des_pages.page6.visible) {
				doc.addImage(this.images_array.page6, "JPEG", 0, 0, 210, 297);
				doc.addPage();
			}
			if (this.$store.state.ordre_des_pages.page7.visible) {
				doc.addImage(this.images_array.page7, "JPEG", 0, 0, 210, 297);
				doc.addPage();
			}
			if (this.$store.state.ordre_des_pages.page8.visible) {
				doc.addImage(this.images_array.page8, "JPEG", 0, 0, 210, 297);
				doc.addPage();
			}
			if (this.$store.state.ordre_des_pages.page9.visible) {
				doc.addImage(this.images_array.page9, "JPEG", 0, 0, 210, 297);
				doc.addPage();
			}
			if (this.$store.state.ordre_des_pages.page10.visible) {
				doc.addImage(this.images_array.page10, "JPEG", 0, 0, 210, 297);
				doc.addPage();
			}
			if (this.$store.state.ordre_des_pages.page11.visible) {
				doc.addImage(this.images_array.page11, "JPEG", 0, 0, 210, 297);
			}
			if (this.$store.state.ordre_des_pages.page12.visible) {
				doc.addPage();
				doc.addImage(this.images_array.page12, "JPEG", 0, 0, 210, 297);
			}

			this.$store.commit("setFreeze", {
				affichage: true,
				texte: "Updating statut.<br /><b>Please wait.</b>",
			});

			var outputBase64 = doc.output("datauristring");
			var preBlob = this.dataURItoBlob(outputBase64);
			var file = new File([preBlob], this.$store.state.offre_active.client.nom + "-" + this.$store.state.offre_active.id_offre + ".pdf", {
				type: "application/pdf",
			});

			const data = new FormData();
			data.append("service", "addmedia");
			data.append("file", file);
			this.$store.commit("setFreeze", {
				affichage: true,
				texte: "Uploading your offer on our server.<br /><b>Please wait.</b>",
			});
			Axios.post("https://ambpicot.pro/api/custom_service/", data).then((res) => {
				this.$store.commit("setFreeze", {
					affichage: true,
					texte: "Offer successfully uploaded.<br /><b>Prepare downloading on your computer.</b>",
				});
				doc.save(this.$store.state.offre_active.client.nom + "-" + this.$store.state.offre_active.id_offre + ".pdf");
				base("offres").update(
					[
						{
							id: this.offer_id,
							fields: {
								statut: "send",
								url_pdf_envoye: res.data.result.lien,
							},
						},
					],
					(err, deletedRecords) => {
						if (err) {
							console.error(err);
							return;
						}
						this.$store.commit("setFreeze", {
							affichage: false,
							texte: "",
						});
						this.$store.commit("statut_offre_active", {
							statut: "send",
						});
						this.$store.commit("pdf_url", res.data.result.lien);
						this.$store.dispatch("listeOffres");
					}
				);
			});
		},
		createPDF() {
			this.$store.commit("setFreeze", {
				affichage: true,
				texte: "We are generating your offer. <br/><b>Please wait for a while</b>",
			});
			this.page1_ready = false;
			this.page2_ready = false;
			this.page3_ready = false;
			this.page4_ready = false;
			this.page5_ready = false;
			this.page6_ready = false;
			this.page7_ready = false;
			this.page8_ready = false;
			this.page9_ready = false;
			this.page10_ready = false;
			this.page11_ready = false;
			this.page12_ready = false;
			window.scrollTo(0, 0);
			var pages = document.querySelectorAll(".page:not(.masked)");
			var ignored_pages = document.querySelectorAll(".page.masked");
			ignored_pages.forEach((page) => {
				this[page.id + "_ready"] = true;
			});
			pages.forEach((item) => {
				item.classList.add("readyforexport");
			});
			setTimeout(() => {
				pages.forEach((page, index) => {
					var cell = page.id + "";
					html2canvas(page, {
						backgroundColor: "#ffffff",
						scale: 1.5,
						width: 1190,
						height: 1684,
						imageTimeout: 40000,
						allowTaint: false,
						useCORS: true,
					})
						.then((canvas) => {
							var image = canvas.toDataURL("image/jpeg", 0.9);
							this[page.id + "_ready"] = true;
							this.images_array[page.id + ""] = image;
							this.verifGeneration();
							page.classList.remove("readyforexport");
						})
						.catch((err) => {
							console.log(page.id, err);
							//this[page.id + "_ready"] = true;
						});
				});
			}, 500);
		},
		deleteOffre() {
			if (confirm("Are you sure you want to delete this offer ?")) {
				base("offres").destroy(this.offer_id, (err, deletedRecords) => {
					if (err) {
						console.error(err);
						return;
					}
					this.$store.commit("initOffreActive");
					this.$store.dispatch("updateOffres", this.$route.params.id);
					this.$router.push("/offres");
				});
			}
		},
		maskOffer() {
			if (confirm("Are you sure you want to hide permanently this offer ?")) {
				base("offres").update(
					[
						{
							id: this.offer_id,
							fields: {
								masquer: true,
							},
						},
					],
					(err, deletedRecords) => {
						if (err) {
							console.error(err);
							return;
						}
						this.$store.commit("setFreeze", {
							affichage: false,
							texte: "",
						});
						this.$store.dispatch("listeOffres");
						setTimeout(() => {
							this.$router.push("/");
						}, 1000);
					}
				);
			}
		},
		update_currencies() {
			base("offres").update(
				[
					{
						id: this.offer_id,
						fields: {
							devise: this.$store.state.offre_active.devise,
						},
					},
				],
				(err, deletedRecords) => {
					if (err) {
						console.error(err);
						return;
					}
					setTimeout(() => {
						window.location.reload();
					}, 1000);
				}
			);
		},
		update_transport_cost() {
			base("offres").update(
				[
					{
						id: this.offer_id,
						fields: {
							cout_transport: parseInt(this.cout_transport),
						},
					},
				],
				(err, deletedRecords) => {
					if (err) {
						console.error(err);
						return;
					}
				}
			);
		},
		handleBox(e) {
			e.target.parentNode.classList.toggle("active");
		},
	},
	mounted() {
		setTimeout(() => {
			if (this.$store.state.offre_active.ordre_des_pages) {
				this.pages = JSON.parse(this.$store.state.offre_active.ordre_des_pages);
				this.$store.commit("resetOrder");
				let temp = {}; // ordre
				let count = 0;
				this.pages.forEach((el, index) => {
					let obj = {}; // Ordre
					if (el.visible) {
						count++;
					}
					obj[el.id] = {
						ordre: index,
						visible: el.visible,
						count: count,
					};
					temp = Object.assign(temp, obj);
				});
				this.$store.commit("updateOrdrePage", temp);
				this.pages_ready = true;
			} else {
				this.pages = [
					{ name: "Frontpage", id: "page1", visible: true },
					{ name: "Letter", id: "page2", visible: true },
					{ name: "Illustration", id: "page3", visible: true },
					{ name: "Description", id: "page4", visible: true },
					{ name: "Sales presentation", id: "page5", visible: true },
					{ name: "Options & Accessoires", id: "page6", visible: true },
					{ name: "Easyroll1", id: "page7", visible: true },
					{ name: "Easyroll2", id: "page8", visible: true },
					{ name: "Configuration", id: "page9", visible: true },
					{ name: "Sales conditions", id: "page10", visible: true },
					{ name: "CGV", id: "page11", visible: true },
					{ name: "Custom Page", id: "page12", visible: false },
				];
				this.$store.commit("resetOrder");
				let temp = {}; // ordre
				let count = 0;
				this.pages.forEach((el, index) => {
					let obj = {}; // Ordre

					if (el.visible) {
						count++;
					}
					obj[el.id] = {
						ordre: index,
						visible: el.visible,
						count: count,
					};
					temp = Object.assign(temp, obj);
				});
				this.$store.commit("updateOrdrePage", temp);
				this.pages_ready = true;
			}
		}, 3000);
	},
};
</script>

<style lang="less">
#tools {
	background: white;
	display: flex;
	flex-direction: column;
	.topbar {
		display: flex;
		flex-direction: column;
		.button_box {
			display: flex;
			font-weight: 500;
			font-size: 12px;
			color: #000;
			align-items: center;
			padding: 15px 20px;
			cursor: pointer;
			text-transform: none;
			display: flex;
			border-bottom: solid 1px rgba(black, 0.05);
			align-items: center;
			transition: all 0.3s ease;
			i {
				margin-right: 10px;
			}
			&:hover {
				background: #f18b00;
				color: white;
				padding-left: 30px;
			}
		}
	}
	.box {
		border-bottom: solid 1px rgba(black, 0.05);
		display: flex;
		flex-direction: column;
		padding: 20px;
		border-bottom: solid 1px rgba(black, 0.05);
		h4 {
			font-size: 14px;
			cursor: pointer;
			margin: 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		&:last-child {
			border-right: none;
		}
		label {
			display: block;
			font-weight: 500;
			font-size: 12px;
			color: black;
			margin-bottom: 10px;
			margin-left: 2px;
		}
		select {
			appearance: none;
			border: none;
			border-radius: 5px;
			font-size: 15px;
			font-weight: 500;
			outline: none;
			padding: 5px 20px;
			background: rgba(black, 0.05);
			border: solid 1px rgba(black, 0.1);
		}
		.content {
			display: none;
		}
		p {
			color: rgba(black, 0.7);
			font-size: 12px;
			b {
				color: #f18b00;
			}
		}
		input[type="number"] {
			background: rgba(black, 0.05);
			border: solid 1px rgba(black, 0.1);
			min-height: 32px;
			padding: 0 20px;
			border-radius: 4px;
			font-size: 13px;
			width: 100%;
			text-align: right;
		}
		select {
			background: rgba(black, 0.05);
			border: solid 1px rgba(black, 0.1);
			min-height: 32px;
			padding: 0 10px;
			border-radius: 4px;
		}
		&.active {
			h4 {
				margin-bottom: 10px;
			}
			.content {
				display: block;
				animation: appearFromBottom 0.3s ease backwards;
			}
		}
	}
	.capacities,
	.transport,
	.currency {
	}
	.daily_change_value {
		padding: 10px 0;
		font-size: 12px;
		color: slategray;
		font-weight: 500;
	}
	.pages_order {
		.page-tuile {
			background: #f4f7fa;
			border-radius: 4px;
			border: solid 1px rgba(black, 0.1);
			padding: 10px;
			margin-bottom: 10px;
			cursor: pointer;
			transition: all 0.3s ease;
			font-size: 12px;
			display: flex;
			justify-content: space-between;
			&:hover {
				background: rgba(#f18b00, 0.05);
			}
		}
		.page_supplementaire {
			font-size: 12px;
			font-weight: 500;
			display: flex;
			padding: 8px 10px;
			justify-content: space-between;
			align-items: center;
		}
	}
	.custom_change {
		label {
			font-weight: 600;
			font-size: 14px;
			margin-bottom: 8px;
			display: block;
		}
		input {
			max-width: 170px;
		}
	}
	.options {
		.options_existantes {
			display: flex;
			flex-direction: column;
			.option {
				display: flex;
				align-items: flex-end;
				justify-content: space-between;
				position: relative;
				padding-right: 20px;
				margin-bottom: 5px;
				padding: 5px 30px 5px 0;
				label {
					font-weight: bold;
					margin: 0;
				}
				.money_format {
					color: #f18b00;
					font-size: 12px;
					font-weight: 500;
					text-align: right;
					white-space: nowrap;
				}
				.remove {
					position: absolute;
					right: 0;
					top: 4px;
					width: 20px;
					height: 20px;
					display: flex;
					font-size: 10px;
					justify-content: center;
					align-items: center;
					background: rgba(black, 0.1);
					border-radius: 50%;
					cursor: pointer;
					&:hover {
						background: red;
						color: white;
					}
				}
			}
		}
		form {
			background: rgba(slategray, 0.1);
			border: solid 1px rgba(slategray, 0.4);
			padding: 10px;
			border-radius: 10px;
			margin-top: 20px;
			button[type="submit"] {
				background: #f18b00;
				border: none;
				padding: 6px 12px;
				border-radius: 20px;
				margin-top: 12px;
				font-size: 12px;
				color: white;
				font-weight: 500;
				cursor: pointer;
				&:hover {
					background: darken(#f18b00, 5%);
					transform: translateY(2px);
				}
			}
			.form-group {
				display: flex;
				gap: 10px;
				align-items: flex-end;
				label {
					font-weight: 500;
					margin: 0;
				}
				input {
					appearance: none;
					background: transparent;
					outline: none;
					border: none;
					border-bottom: solid 1px slategray;
					border-radius: 0px;
					text-align: right;
					padding: 0;
					font-size: 14px;
					width: 100%;
				}
			}
		}
	}
}
</style>

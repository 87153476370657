<template>
	<div>
		<h1>PageNotFound</h1>
	</div>
</template>
<script>
	export default {
		name: "PageNotFound",
		components: {},
		data() {
			return {};
		},
		methods: {}
	};
</script>

<style lang="less">
</style>
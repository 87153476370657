<template>
	<div>
		<Header />
		<main v-if="$store.state.machines_ready && $store.state.clients_ready && $store.state.options_ready && $store.state.commerciaux_ready">
			<Dashboard />
		</main>
		<main v-else>
			<div class="loading">
				<img src="../assets/spinner.gif" />
				<p>We are creating your dashboard. <br /><b>Please wait.</b></p>
			</div>
		</main>
		<Footer />
	</div>
</template>
<script>
import Header from "../components/Header";
import Dashboard from "../components/Dashboard";

export default {
	name: "TableauDeBord",
	components: {
		Header,
		Dashboard,
	},
	data() {
		return {};
	},
	mounted() {},
	methods: {},
};
</script>

<style lang="less">
main {
	display: block;
}
</style>

<template>
	<div id="page8" ref="page8">
		<div class="layout">
			<div class="visuel_pleine_page" v-if="$store.state.offre_active.langue == 'en'" :style="'background-image: url(' + $store.state.easyRoll[0].page2_en[0].url + ')'"></div>
			<div class="visuel_pleine_page" v-if="$store.state.offre_active.langue == 'us'" :style="'background-image: url(' + $store.state.easyRoll[0].page2_us[0].url + ')'"></div>
			<div class="visuel_pleine_page" v-if="$store.state.offre_active.langue == 'ro'" :style="'background-image: url(' + $store.state.easyRoll[0].page2_ro[0].url + ')'"></div>
			<div class="visuel_pleine_page" v-if="$store.state.offre_active.langue == 'de'" :style="'background-image: url(' + $store.state.easyRoll[0].page2_de[0].url + ')'"></div>
			<div class="visuel_pleine_page" v-if="$store.state.offre_active.langue == 'fr'" :style="'background-image: url(' + $store.state.easyRoll[0].page2_fr[0].url + ')'"></div>
			<div class="visuel_pleine_page" v-if="$store.state.offre_active.langue == 'pl'" :style="'background-image: url(' + $store.state.easyRoll[0].page2_pl[0].url + ')'"></div>
			<div class="logo-pied-de-page">
				<img src="../../assets/logofooter.png" />
			</div>
			<PiedDePage :numero="$store.state.ordre_des_pages.page8.count" />
		</div>
	</div>
</template>
<script>
import moment from "moment";
import Airtable from "airtable";
export default {
	name: "page8",
	components: {},
	data() {
		return {};
	},
	methods: {
		handleChange(e) {
			if (this.$store.state.offre_active.statut != "draft") {
				alert("Sorry, you can't modify an issued offer.");
			} else {
				this.$store.commit("setFreeze", {
					affichage: true,
					texte: "Saving your data.<br /><b>Please Wait.</b>",
				});
				this.$store.state.offre_active[e.currentTarget.dataset.field] = e.currentTarget.innerHTML;
				let fields = {
					[e.currentTarget.dataset.field]: e.currentTarget.innerHTML,
				};
				base("offres").update(
					[
						{
							id: this.$store.state.offre_active.id,
							fields: fields,
						},
					],
					(err, records) => {
						this.$store.commit("setFreeze", {
							affichage: false,
							texte: "",
						});
					}
				);
			}
		},
	},
	mounted() {},
};
</script>

<style lang="less">
#page8 .layout {
	display: flex;
	height: calc(~"100% - 32px");
	.visuel_pleine_page {
		background-position: bottom center;
		background-size: contain;
		display: block;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
	}
}
</style>

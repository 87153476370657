<template>
	<div id="dashboard">
		

		<div class="latest">
			<div class="headerbar">
				<div class="caption">
					<h2>
						<span>Latest offers</span>
						
						<span><i class="fal fa-refresh" @click="bigrefresh()"></i></span>
					</h2>
					<p class="sync">Last sync {{ last_sync | moment("YYYY, MMMM DD") }} - {{ offers.length }} offers are displayed</p>
				</div>
				<div class="date_range">
					<select v-model="plage_dates" @change="updateListesOffres()" v-if="!loading">
						<option value="7">Last week</option>
						<option value="30">Last month</option>
						<option value="90">Last quarter</option>
						<option value="180">Last semester</option>
						<option value="365">Last year</option>
						<option value="-1">All</option>
					</select>
					<i v-else class="fal fa-spinner-third fa-spin"></i>
				</div>
			</div>
			
			<div class="liste-projets">
				<p class="no-offer" v-if="offers.length == 0">
					You have no offer yet.
				</p>
				<div class="new_listing_vue">
					<Tuile v-for="item in offers" :key="`tuile${item.id}`" :offre="item" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Tuile from "../components/Tuile.vue";
export default {
	name: "Dashboard",
	components: {Tuile},
	data() {
		return {
			loading: false,
			table_ready: true,
			chargement_visible: true,
			plage_dates: 7,
		};
	},
	mounted() {
		this.$store.commit("setFreeze", {
			affichage: false,
			texte: "",
		});
		
		this.$store
			.dispatch("listeOffres", this.plage_dates)
			.then(() => {
				this.chargement_visible = false;
			})
			.catch((err) => {
				console.log(err);
			});
		
	},
	methods: {
		updateListesOffres() {
			this.$store.commit("setFreeze", {
				affichage: false,
				texte: "",
			});
			this.loading = true;
			this.$store
				.dispatch("listeOffres", this.plage_dates)
				.then(() => {
					this.chargement_visible = false;
					this.loading = false;
				})
				.catch((err) => {
					console.log(err);
					this.loading = false;
				});
			
		},
		bigrefresh() {
			this.$store.dispatch("listeOffres", this.plage_dates).then(() => {
				this.chargement_visible = false;
			});
			this.$store.dispatch("listeMachines");
			this.$store.dispatch("listeClients");
			this.$store.dispatch("listeImages");
			this.$store.dispatch("listeEasyroll");
			this.$store.dispatch("getCGV");
			this.$store.dispatch("getOptions");
			this.$store.dispatch("getGammePrix");
			this.$store.dispatch("getCommerciaux");
		},
		handleRoute(e, a, b) {
			this.$router.push({
				name: "offer",
				params: {
					id: a,
					machine: b,
				},
			});
		},
	},
	computed: {
		...mapGetters(["offres"]),
		...mapState({
			last_sync: "last_sync",
		}),
		offers() {
			return this.$store.state.offres;
		},
	},
};
</script>

<style lang="less">
#dashboard {
	padding: 60px 0;
	margin: 0 auto;
	max-width: 1600px;
	.caption {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		justify-content: flex-start;
		flex: 1;
		h2 {
			color: #f18b00;
			font-size: 40px;
			font-weight: bold;
			margin-bottom: 6px;
			display: flex;
			align-items: center;
			gap: 10px;
			i {
				color: rgb(17 24 39 / 40%);
				font-size: 12px;
				transition: all .4s ease;
				&:hover {
					color: rgb(14 24 39);
					animation: spin 1s infinite;
				}
			}
		}
		.sync {
			color: #88aabd;
			font-size: 13px;
			margin: 0;
		}
		div {
			color: #88aabd;
			margin: 0;
			padding: 0;
			font-size: 13px;
			min-height: 26px;
			transition: all 0.3s ease;
			border-radius: 5px;
		}
		button {
			background: transparent;
			border: none;
			color: #88aabd;
			margin: 0;
			padding: 0;
			font-size: 13px;
			cursor: pointer;
			min-height: 26px;
			padding: 0 10px;
			transition: all 0.3s ease;
			border-radius: 5px;
			&:hover {
				background: #88aabd;
				color: white;
			}
		}
		a {
			color: white;
			font-weight: bold;
			font-size: 14px;
			padding: 10px 20px;
			border-radius: 30px;
			background: linear-gradient(lighten(#f18b00, 15%), #f18b00);
			box-shadow: 6px 6px 20px fadeOut(black, 96%);
			transition: all 0.3s ease;
			&:hover {
				background: linear-gradient(lighten(#f18b00, 5%), #f18b00);
				color: white;
				box-shadow: 6px 6px 20px fadeOut(black, 70%);
			}
		}
	}
	.date_range {
		select {
			// make select looks like tailwind select
			appearance: none;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='rgb(17 24 39 / 40%)' d='M300 288H20c-6.6 0-12 5.4-12 12v20c0 6.6 5.4 12 12 12h280c6.6 0 12-5.4 12-12v-20c0-6.6-5.4-12-12-12z'/%3E%3C/svg%3E");
			background-repeat: no-repeat;
			background-position: right 0.7em top 50%, 0 0;
			background-size: 0.65em auto, 100%;
			// remove default arrow on IE 10 and 11
			&::-ms-expand {
				display: none;
			}
			padding: 0.5em 3.5em 0.5em 1em;
			border: 1px solid rgb(17 24 39 / 40%);
			border-radius: 5px;
			font-family: inherit;
			font-size: 15px;
			font-weight: 500;
			color: rgb(14 24 39 / 60%);
			background-color: transparent;
			cursor: pointer;
			transition: all 0.3s ease;
			&:hover {
				border-color: #f18b00;
			}
			// on focus
			&:focus {
				border-color: #f18b00;
				outline: none;
			}
			// on active
			&:active {
				border-color: #f18b00;
				outline: none;
			}
			

		}
	}
	.tuiles {
		flex: 0 0 auto;
		width: 320px;
		order: 2;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		background: linear-gradient(lighten(black, 98%), #fff);
		box-shadow: 6px 6px 20px fadeOut(black, 96%);
		border-radius: 10px;
		padding: 30px;
		.tuile {
			transition: all 0.3s ease-out;
			flex: 0 0 25%;
			display: flex;
			align-items: flex-end;
			padding: 20px;
			border-bottom: solid 1px rgba(#88aabd, 0.2);
			&:last-child {
				border: none;
			}
			i {
				font-size: 50px;
				color: #f18b00;
				margin-right: 20px;
			}
			.droite {
				flex-direction: column-reverse;
				display: flex;
				text-align: right;
				legend {
					padding: 0;
					font-weight: 500;
					font-size: 13px;
				}
				h3 {
					margin: 0;
					font-size: 24px;
				}
			}

			&:hover {
				color: #f18b00;
			}
		}
	}
	.evolution {
		flex: 0 0 60%;
		margin-top: 80px;
		padding-right: 60px;
		overflow: hidden;
		.chart {
			background: linear-gradient(lighten(black, 96%), #fff);
			border-radius: 10px;
			padding: 100px 20px 20px;
			margin-right: 30px;
			box-shadow: 6px 6px 20px fadeOut(black, 96%);
			transition: all 0.3s ease-out;
			margin-top: 15px;
		}
	}
	.latest {
		.liste-projets {
			display: block;
			margin-top: 35px;
			width: 100%;
			#myTable {
				font-size: 11px;
				width: 100%;
				thead {
					tr {
						th {
							text-align: left;
							font-size: 12px;
							padding: 10px;
						}
					}
				}
				tbody {
					tr {
						cursor: pointer;
						&:nth-child(even) {
							background: rgba(#f18b00, 0.05);
						}
						td {
							padding: 5px 10px;
							&.tools {
								display: flex;
								flex-direction: column;
								align-items: center;
								a,
								div {
									padding: 4px;
									border-radius: 3px;
									margin-bottom: 1px;
									display: flex;
									align-items: center;
									transition: all 0.3s ease;
									i,
									svg {
										margin-right: 3px;
									}
									i.fa-file-pdf {
										font-size: 16px;
										
									}
									&:hover {
										background: rgba(black, 0.05);
									}
								}
							}
						}
					}
				}
			}
			.no-offer {
				color: lighten(black, 50%);
				font-weight: 500;
				font-size: 0.9em;
			}

			.dataTable-wrapper {
				.dataTable-bottom {
					margin: 10px;
					padding: 20px 0;
					border-top: solid 1px rgba(black, 0.1);
					font-size: 11px;
					font-weight: bold;
					.dataTable-pagination {
						display: flex;
						justify-content: center;
						align-items: center;
						list-style: none;
						li {
							padding: 15px;
							&.active {
								color: #f18b00;
								font-weight: bold;
							}
						}
					}
				}
				.dataTable-top {
					display: flex;
					justify-content: space-between;
					margin: 10px;
					padding-bottom: 20px;
					border-bottom: solid 1px rgba(black, 0.07);
					margin-bottom: 20px;
					.dataTable-dropdown {
						font-size: 12px;
						display: flex;
						flex-direction: column;
						font-weight: 500;
					}
					.dataTable-search {
						.dataTable-input {
							background: rgba(#88aabd, 0.05);
							border-radius: 20px;
							border: solid 1px rgba(black, 0.1);
							min-height: 32px;
							padding: 0 20px;
							outline: none;
							&:focus {
								border-color: #f18b00;
							}
						}
					}
				}
				.dataTable-container {
				}
			}
			.new_listing_vue {
				display: grid;
				grid-template-columns: repeat(1, 1fr);
				gap: 10px;
			}
		}
	}
	.dataTable-pagination-list {
		display: flex;
	}
	.headerbar {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: flex-end;
	}

}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>

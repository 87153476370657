<template>
	<div id="page1" ref="page1">
		<div class="lelogopicot">
			<img v-if="$store.state.offre_active.langue == 'us'" src="../../assets/logovertical-us.jpg" />
			<img v-else src="../../assets/logovertical.png" />
		</div>
		<div v-if="$store.state.offre_active.theme.image" class="image"
			:style="'background-image: url(' + $store.state.offre_active.theme.image[0].thumbnails.full.url + ')'">
			<div class="fix"></div>
			<legend></legend>
		</div>
		<div v-else class="image">
			<div class="fix"></div>
		</div>
		<div id="page1-titre" class="titre">{{ $store.state.textes.page1.titre }}</div>
		<div class="date">{{ $store.state.offre_active.date_formatee }}</div>
		<div class="references">
			<div id="page1-affaire" class="affaire">
				{{ $store.state.textes.page1.affaire }}
				<strong @blur="handleChange" data-field="id_clipper" contenteditable="true"
					v-if="$store.state.offre_active.id_clipper && $store.state.offre_active.id_clipper != '-'"
					v-html="$store.state.offre_active.id_clipper" />
				<strong @blur="handleChange" data-field="id_clipper" contenteditable="true" v-else>{{ $store.state.offre_active.id_offre }}</strong>
			</div>
			<div id="page1-destinaire" class="destinataire">
				{{ $store.state.textes.page1.attention }}
				<strong>{{ $store.state.offre_active.client.contact_nom }} - {{
					$store.state.offre_active.client.nom }}</strong>
			</div>
			<div class="case">
				<span contenteditable="true" @blur="handleChange" data-field="page_1_textebas"
					v-html="$store.state.offre_active.page_1_textebas || $store.state.textes.page1.suivie">
				</span>
				<strong>{{ $store.state.user.display_name }}</strong>
			</div>
		</div>
		<div class="bebe">
			<img src="../../assets/bebe-picot.jpg" class="lebebe" />
			<img  v-if="$store.state.offre_active.langue == 'us'" src="../../assets/easy2roll.png" class="easyroll"  />
			<img  v-else src="../../assets/easyroll_logo.png" class="easyroll" />
			<div class="catchphrase" v-html="$store.state.textes.page1.easyroll"></div>
		</div>
		<div class="line"></div>
	</div>
</template>
<script>
import moment from "moment";
import Airtable from "airtable";
const base = new Airtable({ apiKey: "patbf88vmcP5sIIit.ee71112a4ec13dfd6d6e7072aedf4fe93d4a1a67c5f56495c75a4c178ef61a90" }).base("appsk0eykwa6zk25e");
export default {
	name: "page1",
	components: {},
	data() {
		return {};
	},
	methods: {
		handleChange(e) {
			if (this.$store.state.offre_active.statut != "draft") {
				alert("Sorry, you can't modify an issued offer.");
			} else {
				this.$store.commit("setFreeze", {
					affichage: true,
					texte: "Saving your data.<br /><b>Please Wait.</b>",
				});
				this.$store.commit("setActiveElement", {
					field: e.currentTarget.dataset.field,
					value: e.currentTarget.innerHTML,
				});

				let fields = {
					[e.currentTarget.dataset.field]: e.currentTarget.innerHTML,
				};
				base("offres").update(
					[
						{
							id: this.$store.state.offre_active.id,
							fields: fields,
						},
					],
					(err, records) => {
						this.$store.commit("setFreeze", {
							affichage: false,
							texte: "",
						});
					}
				);
			}
		},
	},
};
</script>
<style lang="less" scoped>
#page1 {
	word-break: break-word;
	.image {
		width: 100%;
		position: relative;
		background-position: center center;
		background-size: cover;
		legend {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 100px;
			background: #f18b00;
			mix-blend-mode: multiply;
			font-weight: 500;
			font-size: 14px;
			color: white;
		}
		.fix {
			padding-bottom: 110%;
			display: block;
			width: 100%;
		}
	}
	.lelogopicot {
		position: absolute;
		top: 40;
		left: 40;
		z-index: 2;
		background: white;
		padding: 20px;
		box-shadow: 4px 4px 4px fadeOut(black, 90%);
		img {
			width: 180px;
			height: auto;
		}
	}
	.titre {
		margin-top: 50px;
		font-size: 50px;
		font-weight: bold;
		display: block;
	}
	.date {
		display: flex;
		align-items: center;
		font-size: 30px;
		font-weight: 500;
		color: #f18b00;
		&:before {
			width: 30px;
			height: 8px;
			background: black;
			content: "";
			display: block;
			margin-right: 10px;
		}
	}
	.references {
		padding-left: 40px;
		font-size: 20px;
		padding-top: 20px;
		line-height: 30px;
		font-weight: 300;
		display: block;
		max-width: 700px;
		color: lighten(black, 30%);
		strong {
			font-weight: normal;
			color: black;
		}
		.affaire {
			font-weight: bold;
			color: black;
			font-style: italic;
			text-transform: uppercase;
			font-size: 0.85em;
			strong {
				font-weight: bold;
			}
		}
		.case {
			span {
				margin-right: 5px;
			}
		}
	}
	.line {
		position: absolute;
		bottom: 50px;
		left: 90px;
		right: 50px;
		height: 2px;
		background-color: #f18b00;
		display: block;
	}
	.bebe {
		position: absolute;
		bottom: 50px;
		right: 50px;
		height: 330px;
		.lebebe {
			height: 300px;
			width: auto;
			position: absolute;
			right: 0;
			bottom: 0;
			z-index: 0;
		}
		.easyroll {
			position: absolute;
			right: 0;
			top: -30px;
			height: 50px;
		}
		.catchphrase {
			position: absolute;
			right: 20px;
			top: 50px;
			width: 205px;
			font-size: 0.9em;
			line-height: 20px;
			text-align: right;
		}
	}
}
</style>

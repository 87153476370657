<template>
	<div id="myclient">
		<Header />
		<main>
			<div class="part1">
				<h2>Contacts</h2>
				<div class="clients">
					<div class="client" v-for="client in clients" :key="client.id" @click="handleUpdate(client.id)">
						<span class="nom" v-html="client.fields.nom"></span>
						<span class="contact">
							<i class="fal fa-user"></i>
							{{ client.fields.contact_nom }}
						</span>
						<span class="email">
							<i class="fal fa-envelope-open"></i>
							{{ client.fields.contact_email }}
						</span>
					</div>
				</div>
			</div>
			<div class="part2">
				<form v-if="update_mode" id="update_client" @submit.prevent="updateClient">
					<h1>
						Update <span>{{ update_nom }}</span>
					</h1>
					<div class="gauche">
						<h2><i class="fal fa-building"></i>About the compagny</h2>
						<div class="line">
							<i class="fal fa-building"></i>
							<input type="text" v-model="update_nom" id="compagny_name" required placeholder="Compagny name" />
						</div>
						<div class="line">
							<i class="fal fa-map-marker"></i>
							<input type="text" v-model="update_adresse" id="compagny_address" required placeholder="address" />
						</div>
						<div class="line">
							<i class="fal fa-map-signs"></i>
							<input type="text" v-model="update_cp" id="compagny_cp" required placeholder="ZIP code" />
						</div>
						<div class="line">
							<i class="fal fa-university"></i>
							<input type="text" v-model="update_ville" id="compagny_city" required placeholder="City" />
						</div>

						<div class="line">
							<i class="fal fa-caret-down"></i>
							<select id="compagny_country" v-model="update_pays" placeholder="Country">
								<option v-for="country in countries" :key="country.name" :value="country.name">{{ country.name }}</option>
							</select>
						</div>
					</div>
					<div class="droite">
						<h2><i class="fal fa-user"></i>About the contact</h2>
						<div class="line">
							<i class="fal fa-user-tie"></i>
							<input type="text" v-model="update_contact_nom" id="compagny_maincontact_name" required placeholder="Contact Name" />
						</div>
						<div class="line">
							<i class="fal fa-envelope"></i>
							<input type="email" v-model="update_contact_email" id="compagny_maincontact_email" required placeholder="Contact Email" />
						</div>
						<div class="line">
							<i class="fal fa-phone"></i>
							<input type="tel" v-model="update_contact_mobile" id="compagny_maincontact_mobile" placeholder="Mobile Phone" />
						</div>
						<div class="line">
							<i class="fal fa-phone-rotary"></i>
							<input type="tel" v-model="update_contact_telephone" id="compagny_maincontact_telephone" placeholder="Contact Phone" />
						</div>
						<div class="line submit">
							<button type="submit">Update contact</button>
							<div class="removeClient" @click="removeClient(update_id)">Remove contact</div>
						</div>
					</div>
				</form>
				<form id="create_new_client" @submit.prevent="createNewCustomerInAirtable">
					<h1>Create a new client</h1>
					<div v-if="$store.state.user.role.administrator" class="clipper">
						<div class="logo_clipper">
							<img src="../assets/logo_clipper.png" />
						</div>
						<div class="droite">
							<strong>Wahouuu !</strong>
							You can create a new customer from <span>Clipper generated file.</span><br />
							<label for="file">Please choose Excel file on your computer</label>
							<input type="file" ref="file" name="file" id="file" />
						</div>
					</div>
					<div class="gauche">
						<h2><i class="fal fa-building"></i>About the compagny</h2>
						<div class="line">
							<i class="fal fa-building"></i>
							<input type="text" v-model="nom" id="compagny_name" required placeholder="Compagny name" />
						</div>
						<div class="line">
							<i class="fal fa-map-marker"></i>
							<input type="text" v-model="adresse" id="compagny_address" required placeholder="address" />
						</div>
						<div class="line">
							<i class="fal fa-map-signs"></i>
							<input type="text" v-model="cp" id="compagny_cp" required placeholder="ZIP code" />
						</div>
						<div class="line">
							<i class="fal fa-university"></i>
							<input type="text" v-model="ville" id="compagny_city" required placeholder="City" />
						</div>

						<div class="line">
							<i class="fal fa-caret-down"></i>
							<select id="compagny_country" v-model="pays" placeholder="Country">
								<option v-for="country in countries" :key="country.name" :value="country.name">{{ country.name }}</option>
							</select>
						</div>
					</div>
					<div class="droite">
						<h2><i class="fal fa-user"></i>About the contact</h2>
						<div class="line">
							<i class="fal fa-user-tie"></i>
							<input type="text" v-model="contact_nom" id="compagny_maincontact_name" required placeholder="Contact Name" />
						</div>
						<div class="line">
							<i class="fal fa-envelope"></i>
							<input type="email" v-model="contact_email" id="compagny_maincontact_email" required placeholder="Contact Email" />
						</div>
						<div class="line">
							<i class="fal fa-phone"></i>
							<input type="tel" v-model="contact_mobile" id="compagny_mobile_telephone" placeholder="Mobile Phone" />
						</div>
						<div class="line">
							<i class="fal fa-phone-rotary"></i>
							<input type="tel" v-model="contact_telephone" id="compagny_maincontact_telephone" placeholder="Contact Phone" />
						</div>
						<div class="line">
							<button type="submit">Create contact</button>
						</div>
					</div>
				</form>
			</div>
		</main>
	</div>
</template>
<script>
import Airtable from "airtable";
import Header from "../components/Header";
//import { _ } from "underscore";
import countries from "../assets/json/countries.json";
import xlsParser from "xls-parser";
var base = new Airtable({ apiKey: "patbf88vmcP5sIIit.ee71112a4ec13dfd6d6e7072aedf4fe93d4a1a67c5f56495c75a4c178ef61a90" }).base("appsk0eykwa6zk25e");
export default {
	name: "Clients",
	components: { Header },
	data() {
		return {
			update_mode: false,
			countries: countries,
			nom: "",
			adresse: "",
			cp: "",
			ville: "",
			pays: "France",
			contact_nom: "",
			contact_email: "",
			contact_mobile: "",
			contact_telephone: "",
			commercial_attache: "",
			update_id: "",
			update_nom: "",
			update_adresse: "",
			update_cp: "",
			update_ville: "",
			update_pays: "",
			update_contact_nom: "",
			update_contact_email: "",
			update_contact_mobile: "",
			update_contact_telephone: "",
			update_commercial_attache: "",
		};
	},
	methods: {
		createNewCustomerInAirtable() {
			var newclient = {
				nom: this.nom,
				adresse: this.adresse,
				cp: this.cp,
				ville: this.ville,
				pays: this.pays,
				contact_nom: this.contact_nom,
				contact_email: this.contact_email,
				contact_mobile: this.contact_mobile,
				contact_telephone: this.contact_telephone,
				commercial_attache: parseInt(this.$store.state.user.ID),
			};
			this.$store.commit("setFreeze", {
				affichage: true,
				texte: "",
			});
			base("clients").create(
				[
					{
						fields: newclient,
					},
				],
				(err, records) => {
					if (err) {
						console.error(err);
						return;
					}
					this.$store.dispatch("listeClients");
					records.forEach((record) => {
						this.client = record.getId();
						this.$store.commit("setFreeze", {
							affichage: false,
							texte: "",
						});
					});
				}
			);
		},
		handleUpdate(client) {
			this.update_mode = true;
			this.clients.map((item) => {
				if (item.id == client) {
					this.update_id = client;
					this.update_nom = item.fields.nom;
					this.update_adresse = item.fields.adresse;
					this.update_cp = item.fields.cp;
					this.update_ville = item.fields.ville;
					this.update_pays = item.fields.pays;
					this.update_contact_nom = item.fields.contact_nom;
					this.update_contact_email = item.fields.contact_email;
					this.update_contact_mobile = item.fields.contact_mobile;
					this.update_contact_telephone = item.fields.contact_telephone;
					this.update_commercial_attache = item.fields.commercial_attache;
				}
			});
		},
		removeClient(id) {
			this.$store.commit("setFreeze", {
				affichage: true,
				texte: "We are deleting contact<br /><b>Please wait.</b>",
			});
			base("clients").destroy([id], (err, deletedRecords) => {
				if (err) {
					console.error(err);
					return;
				}
				this.$store.dispatch("listeClients");
				this.$store.commit("setFreeze", {
					affichage: false,
					texte: "",
				});
			});
		},
		updateClient() {
			this.$store.commit("setFreeze", {
				affichage: true,
				texte: "We are updating contact.<br /><b>Please wait.</b>",
			});
			base("clients").update(
				[
					{
						id: this.update_id,
						fields: {
							nom: this.update_nom,
							adresse: this.update_adresse,
							cp: this.update_cp,
							ville: this.update_ville,
							pays: this.update_pays,
							contact_nom: this.update_contact_nom,
							contact_email: this.update_contact_email,
							contact_mobile: this.update_contact_mobile,
							contact_telephone: this.update_contact_telephone,
							commercial_attache: this.update_commercial_attache,
						},
					},
				],
				(err, deletedRecords) => {
					if (err) {
						console.error(err);
						return;
					}
					this.$store.dispatch("listeClients");
					setTimeout(() => {
						this.$store.commit("setFreeze", {
							affichage: false,
							texte: "",
						});
					}, 2000);
				}
			);
		},
		readXLS(e) {
			this.$store.commit("setFreeze", {
				affichage: true,
				texte: "We are reading the .xls file<br /><b>Please wait.</b><br /><b>It's huge.</b>",
			});
			var data = e.target.files[0];
			xlsParser.onFileSelection(data).then((el) => {
				var societe = el["Base de donnée"][0]["Sté"];
				var nom = el["Base de donnée"][0]["Nom"];
				var adresse = el["Base de donnée"][0]["Adresse #1"] + el["Base de donnée"][0]["Adresse #2"] + el["Base de donnée"][0]["Adresse #3"];
				var tel = el["Base de donnée"][0]["Tel"];
				var mail = el["Base de donnée"][0]["Mail"];
				var machine = el["Base de donnée"][0]["Machine"];
				this.nom = societe;
				this.adresse = adresse;
				(this.cp = ""),
					(this.ville = ""),
					(this.pays = ""),
					(this.contact_nom = nom),
					(this.contact_email = mail),
					(this.contact_mobile = ""),
					(this.contact_telephone = tel),
					this.$store.commit("setFreeze", {
						affichage: false,
						texte: "",
					});
			});
		},
	},
	mounted() {
		this.$refs.file.addEventListener("change", this.readXLS);
	},
	computed: {
		clients() {
			return this.$store.state.clients;
		},
	},
};
</script>

<style lang="less">
#myclient {
	main {
		padding: 0 0 0 0;
		display: flex;
		flex-wrap: wrap;
		.part1 {
			flex: 0 0 320px;
			background: white;
			box-shadow: -25px 0px 50px fadeOut(black, 94%);
			position: relative;
			height: 100vh;
			padding-bottom: 100px;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			h2 {
				padding: 40px;
				font-size: 30px;
			}
			.clients {
				.client {
					display: block;
					padding: 10px 40px;
					position: relative;
					cursor: pointer;
					border-bottom: solid 1px fadeOut(black, 96%);
					span {
						display: block;
						&.nom {
							font-weight: bold;
							font-size: 16px;
							margin-bottom: 4px;
						}
						&.contact,
						&.email {
							font-size: 12px;
							line-height: 20px;
							color: lighten(black, 50%);
							i {
								color: #f18b00;
							}
						}
					}
					&:after {
						content: "";
						top: 0;
						bottom: 0;
						width: 0px;
						transition: all 0.3s ease;
						right: 0;
						background: #f18b00;
						position: absolute;
					}
					&:hover {
						&:after {
							width: 3px;
						}
					}
				}
			}
		}
		.part2 {
			height: 100vh;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			flex: 1;
			padding: 50px;
			#create_new_client,
			#update_client {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				align-items: flex-start;
				width: 100%;
				margin-top: 40px;
				padding: 40px;
				background: linear-gradient(lighten(black, 98%), #fff);
				border-radius: 10px;
				box-shadow: 6px 6px 20px fadeOut(black, 96%);
				transition: all 0.3s ease-out;
				.gauche {
					flex: 0 0 50%;
					margin-top: 40px;
					padding-right: 50px;
				}
				.droite {
					flex: 0 0 50%;
					margin-top: 40px;
					transition: all 0.3s ease-out;
					padding-right: 50px;
				}
				h1 {
					flex: 0 0 100%;
					font-size: 30px;
					margin: 0;
					span {
						color: lighten(black, 50%);
					}
				}
				h2 {
					font-size: 16px;
					display: flex;
					align-items: center;
					i {
						font-size: 22px;
						padding-left: 14px;
						line-height: 22px;
						width: 40px;
					}
				}

				.clipper {
					flex: 0 0 100%;
					font-size: 16px;
					line-height: 22px;
					margin-top: 40px;
					padding: 20px;
					border-radius: 10px;
					border: solid 2px #ef3842;
					display: flex;
					.logo_clipper {
						padding-right: 40px;
						flex: 0 0 auto;
						img {
							height: 100px;
							width: auto;
						}
					}
					.droite {
						margin-top: 0;
						strong {
							display: block;
							color: #ef3842;
							font-size: 26px;
							margin-bottom: 5px;
						}
						span {
							color: #ef3842;
						}
						label {
							display: block;
							margin-top: 15px;
							font-size: 13px;
							color: black;
							font-weight: 500;
						}
					}
				}
				.line {
					display: block;
					width: 100%;
					position: relative;
					padding: 0px;
					margin-bottom: 15px;
					button {
						background: #f18b00;
						border: none;
						padding: 12px 40px 12px 30px;
						border-radius: 10px;
						color: white;
						font-weight: bold;
						font-size: 14px;
						outline: none;
						cursor: pointer;
						border-bottom: solid 3px darken(#f18b00, 10%);
						i {
							padding-right: 10px;
							font-size: 1.2em;
							transform: translateY(2px);
						}
						&:hover {
							border-bottom-width: 1px;
							background: darken(#f18b00, 2%);
						}
					}
					label {
						display: block;
						font-weight: 500;
						padding: 0 0 10px 10px;
					}
					i {
						color: lighten(black, 80%);
						font-size: 20px;
						position: absolute;
						left: 15px;
						top: 10px;
					}
					input[type="text"],
					input[type="tel"],
					input[type="email"],
					select {
						border: none;
						outline: none;
						width: 100%;
						height: 40px;
						line-height: 40px;
						background: white;
						padding-left: 40px;
						border: solid 1px lighten(black, 80%);
						border-radius: 20px;
						font-size: 15px;
						font-weight: 500;
						appearance: none;
						color: lighten(black, 60%);
						transition: all 0.3s ease;
						&:focus {
							color: black;
							border-color: #f18b00;
							box-shadow: 0px 2px 10px fadeOut(#f18b00, 60%);
						}
					}
					.double {
						display: flex;
						align-items: center;
						.adhoc {
							color: lighten(black, 20%);
							text-decoration: underline;
							font-size: 12px;
							padding: 0 0 10px 10px;
						}
					}
					&.submit {
						display: flex;
						align-items: center;
						.removeClient {
							font-size: 13px;
							padding-left: 20px;
							cursor: pointer;
							color: black;
							font-weight: 500;
						}
					}
				}
			}
		}
	}
}
</style>

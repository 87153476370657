import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import VTooltip from "v-tooltip";
import VueCurrencyFilter from "vue-currency-filter";
import filters from "./filters";
import VueUnits from "vue-units";
import VueMoment from "vue-moment";
import VueContext from "vue-context";
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(VueAxios, axios);
Vue.use(VTooltip);
Vue.use(VueMoment);
Vue.use(VueUnits);
Vue.use(Vant);

Vue.use(VueCurrencyFilter, [
	{
		symbol: "€",
		thousandsSeparator: " ",
		fractionCount: 2,
		fractionSeparator: ",",
		symbolPosition: "back",
		symbolSpacing: true,
	},
	{
		name: "currency_USD",
		thousandsSeparator: ",",
		fractionCount: 2,
		fractionSeparator: ".",
		symbolPosition: "front",
		symbolSpacing: true,
		avoidEmptyDecimals: "",
	},
]);
Vue.config.productionTip = false;
Vue.component("PiedDePage", () => import("./components/PiedDePage.vue"));

new Vue({
	components: {
		VueContext,
	},
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");

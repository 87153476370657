<template>
	<div id="page11" ref="page11">
		<div class="layout">
			<div class="logo">
				<img src="../../assets/logovertical.png" />
			</div>
			<div class="conditions" v-if="$store.state.offre_active.langue == 'en'" v-html="$store.state.conditions.cgv_en"></div>
			<div class="conditions" v-if="$store.state.offre_active.langue == 'us'" v-html="$store.state.conditions.cgv_us"></div>
			<div class="conditions" v-if="$store.state.offre_active.langue == 'ro'" v-html="$store.state.conditions.cgv_ro"></div>
			<div class="conditions" v-if="$store.state.offre_active.langue == 'fr'" v-html="$store.state.conditions.cgv_fr"></div>
			<div class="conditions" v-if="$store.state.offre_active.langue == 'de'" v-html="$store.state.conditions.cgv_de"></div>
			<div class="conditions" v-if="$store.state.offre_active.langue == 'pl'" v-html="$store.state.conditions.cgv_pl"></div>

			<div class="logo-pied-de-page">
				<img src="../../assets/logofooter.png" />
			</div>
			<PiedDePage :numero="$store.state.ordre_des_pages.page11.count" />
		</div>
	</div>
</template>
<script>
import moment from "moment";
import Airtable from "airtable";
export default {
	name: "page10",
	components: {},
	data() {
		return {};
	},
	methods: {
		handleChange(e) {
			this.$store.state.offre_active[e.currentTarget.dataset.field] = e.currentTarget.innerHTML;
		},
	},
	mounted() {},
};
</script>

<style lang="less">
#page11 .layout {
	display: flex;
	flex-wrap: wrap;
	.logo {
		flex: 0 0 100%;
		padding: 0 15px;
		img {
			height: 120px;
			width: auto;
		}
	}
	.conditions {
		columns: 2;
		column-gap: 30px;
		font-size: 11px;
		line-height: 12px;
		text-align: justify;
		overflow: hidden;
		padding: 10px;
		color: lighten(black, 20%);
		max-height: 1450px;
		h1 {
			font-weight: bold;
			color: black;
			font-size: 16px;
			text-decoration: none !important;
			text-transform: none !important;
			margin: 0 0 6px 0;
			span {
				text-decoration: none !important;
				text-transform: none !important;
			}
		}
		h2 {
			font-size: 14px;
			margin: 6px 0;
			color: black;
			text-decoration: none !important;
			span {
				text-decoration: none !important;
				text-transform: none !important;
			}
		}
	}
}
</style>

import Vue from "vue";
import Vuex from "vuex";
import Airtable from "airtable";
import translations from "./assets/translations/textes.json";
import Axios from "axios";
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
	storage: window.window.sessionStorage,
	key: `picot-${process.env.VUE_APP_VERSION}`,
	modules: [
		"user",
		"connected",
		"machines",
		"conditions",
		"images",
		"clients",
		"cgv",
		"options",
		"commerciaux",
		"prices",
		"offres_ready",
		"machines_ready",
		"clients_ready",
		"options_ready",
		"commerciaux_ready",
		"easyRoll",
	],
});
const base = new Airtable({ apiKey: "patbf88vmcP5sIIit.ee71112a4ec13dfd6d6e7072aedf4fe93d4a1a67c5f56495c75a4c178ef61a90" }).base("appsk0eykwa6zk25e");
Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		createofferOpen: false,
		connected: false,
		user: {},
		last_sync: null,
		offres: [],
		offres_ready: false,
		machines_ready: false,
		machines: [],
		offre_active: {},
		offre_ready: false,
		images: {},
		clients: {},
		clients_ready: false,
		textes: {},
		freeze: false,
		freeze_message: "We are loading some stuff.<br /><b>Please wait.</b>",
		easyRoll: [],
		cgv: [],
		conditions: [],
		montant_total: 0,
		options: [],
		logs: [],
		languages: [
			{
				value: "en",
				label: "English",
			}, {
				value: "fr",
				label: "Français",
			}, {
				value: "de",
				label: "Deutsch",
			}, {
				value: "pl",
				label: "Polski",
			}, {
				value: "us",
				label: "US English",
			}, {
				value: "ro",
				label: "Română",
			}
		],
		options_ready: false,
		commerciaux_ready: false,
		commerciaux: [],
		capacities2: false,
		ordre_des_pages: {
			page1: {
				order: 0,
				visible: true,
			},
			page2: {
				order: 1,
				visible: true,
			},
			page3: {
				order: 2,
				visible: true,
			},
			page4: {
				order: 3,
				visible: true,
			},
			page5: {
				order: 4,
				visible: true,
			},
			page6: {
				order: 5,
				visible: true,
			},
			page7: {
				order: 6,
				visible: true,
			},
			page8: {
				order: 7,
				visible: true,
			},
			page9: {
				order: 8,
				visible: true,
			},
			page10: {
				order: 9,
				visible: true,
			},
			page11: {
				order: 10,
				visible: true,
			},
			page12: {
				order: 11,
				visible: true,
			},
		},
		prices: [],
		stats: {
			draft: 0,
			send: 0,
			decline: 0,
			accept: 0,
		},
		tools: {
			cout_transport: 0,
		},
		currencies: {},
	},
	mutations: {
		mutate_createofferOpen(state, payload) {
			state.createofferOpen = payload;
		},
		updateCoutTransport(state, payload) {
			state.offre_active.cout_transport = payload;
			console.log(state.offre_active.cout_transport);
		},
		resetOrder(state) {
			state.ordre_des_pages = {};
		},
		setCapacities2(state, payload) {
			state.capacities2 = payload;
		},
		updateOrdrePage(state, payload) {
			state.ordre_des_pages = payload;
		},
		disconnect(state) {
			state.connected = false;
			state.user = {};
			state.offres = {};
			state.offre_active = {};
			state.machines = [];
		},
		setUser(state, payload) {
			this.state.user = payload;
			this.dispatch("addLog");
			//this.dispatch("listeOffres");
			this.dispatch("listeMachines");
			//this.dispatch("getCurrencies");
			this.dispatch("listeClients");
			this.dispatch("listeImages");
			this.dispatch("listeEasyroll");
			this.dispatch("getCGV");
			this.dispatch("getOptions");
			this.dispatch("getGammePrix");
			this.dispatch("getCommerciaux");
		},
		initOffreActive(state) {
			state.offre_active = {};
			state.offre_ready = false;
		},
		setConnected(state, payload) {
			this.state.connected = payload;
		},
		setOffres(state, payload) {
			this.state.offres = payload;
			this.state.offres_ready = true;
			this.state.freeze = false;
		},
		addOffre(state, payload) {
			state.offres.push(payload);
		},
		setActiveOffre(state, payload) {
			state.capacities2 = false;
			this.state.offre_active = payload;
			this.dispatch("translations", payload.langue);

			// Machines
			let difference = parseInt(state.offre_active.machine.prix_distrib) * state.offre_active.gamme_de_prix_machine[0];
			state.offre_active.machine.prix_distrib = parseInt(state.offre_active.machine.prix_distrib) + difference;

			// Options
			if (state.offre_active.page9_options) {
				state.offre_active.page9_options = JSON.parse(state.offre_active.page9_options);
			} else {
				state.offre_active.page9_options = [];
			}

			// Options payants
			if (!state.offre_active.extra_options) {
				state.offre_active.extra_options_treated = [];
			} else {
				state.offre_active.extra_options_treated = JSON.parse(state.offre_active.extra_options);
			}

			this.state.freeze = false;
			this.state.offre_ready = true;
		},
		setMachines(state, payload) {
			this.state.machines = payload;
			this.state.machines_ready = true;
		},
		setTextes(state, payload) {
			this.state.textes = payload;
			this.state.offre_ready = true;
		},
		setClients(state, payload) {
			state.clients = payload;
			state.clients_ready = true;
		},
		addClients(state, payload) {
			state.clients.push(payload);
		},
		setImages(state, payload) {
			state.images = payload;
		},
		setFreeze(state, payload) {
			state.freeze = payload.affichage;
			if (payload.texte != "") {
				state.freeze_message = payload.texte;
			} else {
				state.freeze_message = "We are loading some stuff.<br /><b>Please wait.</b>";
			}
		},
		setEasyroll(state, payload) {
			this.state.easyRoll = payload;
		},
		setCGV(state, payload) {
			this.state.cgv = payload;
		},
		setLogs(state, payload) {
			state.logs = payload;
		},
		totaux_page_5(state, payload) {
			state.offre_active.sous_total_machines = payload.a;
			state.offre_active.total = payload.b;
		},
		totaux_page_6(state, payload) {
			state.offre_active.sous_total_controls = payload.a;
			state.offre_active.sous_total_accessoires = payload.b;
			state.offre_active.total = payload.c;
		},
		totaux_offre_active(state, payload) {
			state.offre_active.sous_total_machines = payload.a;
			state.offre_active.sous_total_controls = payload.b;
			state.offre_active.sous_total_accessoires = payload.c;
			state.offre_active.total = payload.d;
		},
		statut_offre_active(state, payload) {
			state.offre_active.statut = payload.statut;
		},
		pdf_url(state, payload) {
			state.offre_active.url_pdf_envoye = payload;
		},
		setActiveElement(state, payload) {
			if (state.offre_active.page2_intitule === undefined) {
				state.offre_active.page2_intitule = "";
			}
			state.offre_active[payload.field] = payload.value;
		},
		addOption(state, payload) {
			if (state.offre_active.page9_options) {
				let parsed = JSON.parse(state.offre_active.page9_options);
				parsed.push({
					intitule: payload.target[0].value,
					prix: payload.target[1].value,
				});
				state.offre_active.page9_options = JSON.stringify(parsed);
			} else {
				let parsed = [];
				parsed.push({
					intitule: payload.target[0].value,
					prix: payload.target[1].value,
				});
				state.offre_active.page9_options = JSON.stringify(parsed);
			}
		},
		setCostOptions(state, payload) {
			state.offre_active.extra_options_treated.push({
				label: payload.label,
				price: payload.price,
			});
		},
	},
	actions: {
		createOffer({state}, payload) {
			return new Promise( (resolve, reject) => {
				base("offres").create(
					[
						{
							fields: payload,
						},
					],
					(err, records) => {
						if (err) {
							reject(err);
							return;
						}
						resolve(records[0]);
					}
				);
			})
		},
		findOffers({state}, payload) {
			return new Promise((resolve, reject) => {
				if(!payload) {
					reject('Aucun terme de recherche');
					return;
				}
				let allRecords = [];
				let lowerPayload = payload.toLowerCase();
				let query = {
					view: "listes_des_offres",
					pageSize: 100,
					sort: [{ field: "date_modif", direction: "desc" }],
					fields: [
						"id_offre",
						"id_clipper",
						"date_modif",
						"nom_commercial",
						"machine_name",
						"theme_name",
						"gamme_de_prix_name",
						"nom_client",
						"image_machine",
						"date_cree",
						"statut"
					]
				};
				if(!state.user.role.administrator) {
					query.filterByFormula = "{id_commercial}=" + state.user.ID;
				} 
				base('offres').select(query).eachPage(function page(records, fetchNextPage) {
					records.forEach((record) => {
						let match = 0;
						if(record.fields.nom_client) {
							if(record.fields.nom_client[0].toLowerCase().includes(lowerPayload)) {
								match++;
							}
						}
						if(record.fields.nom_commercial) {
							if(record.fields.nom_commercial.toLowerCase().includes(lowerPayload)) {
								match++;
							}
						}
						if(record.fields.machine_name) {
							if(record.fields.machine_name[0].toLowerCase().includes(lowerPayload)) {
								match++;
							}
						}
						if(record.fields.id_offre) {
							if(record.fields.id_offre.toLowerCase().includes(lowerPayload)) {
								match++;
							}
						}
						if(record.fields.theme_name) {
							if(record.fields.theme_name[0].toLowerCase().includes(lowerPayload)) {
								match++;
							}
						}
						if(match > 0) {
							allRecords.push(record);
						}
					});

					fetchNextPage();
				}, function done(records, err) {
					if (err) { 
						console.error(err); 
						reject(err);
						return; 
					} else {
						resolve(allRecords);
					}
					
				});
			});
		},
		refresh_total({ state }) {
			state.offre_active.total =
				state.offre_active.machine.prix_distrib +
				state.offre_active.sous_total_machines +
				state.offre_active.sous_total_controls +
				state.offre_active.sous_total_accessoires +
				0.01;
			setTimeout(() => {
				state.offre_active.total -= 0.01;
			}, 500);
		},
		getCurrencies({ state }) {
			Axios.get("https://api.freecurrencyapi.com/v1/latest?apikey=0hXbl4scu3FauUpuvtGAMOejXAYnYa7kmvgT3n7A&base_currency=EUR")
				.then((res) => {
					state.currencies = res.data.data;
				})
				.catch((err) => {
					console.log(err);
				});
		},
		activeOffre({ commit, state }, value) {
			state.offre_ready = false;
			commit("setFreeze", {
				affichage: true,
				texte: "Loading data.<br /><b>Please Wait.</b>",
			});
			base("offres").find(value, function(err, record) {
				record.fields.id = record.id;
				base("clients").find(record.fields.client[0], function(err, el) {
					record.fields.client = el.fields;
					base("machines").find(record.fields.machine[0], function(err, el2) {
						record.fields.machine = el2.fields;
						base("images").find(record.fields.theme[0], function(err, el3) {
							record.fields.theme = el3.fields;
							
							commit("setActiveOffre", record.fields);
							console.log(record.fields);
						});
					});
				});
			});
		},
		listeMachines({ commit, state }) {
			var tmp_machines = [];
			base("machines")
				.select({
					view: "liste_des_machines",
					fields: ["name", "prix_distrib", "reserver_picot", "image_machine"],
				})
				.eachPage(
					function page(records, fetchNextPage) {
						records.forEach((item) => {
							if (item.fields.reserver_picot) {
								if (state.user.role.administrator) {
									tmp_machines.push(item);
								}
							} else {
								tmp_machines.push(item);
							}
						});
						fetchNextPage();
					},
					function done(err) {
						if (err) {
							console.error(err);
							return;
						}
						commit("setMachines", tmp_machines);
						state.machines_ready = true;
					}
				);
		},
		listeImages({ commit }) {
			var tmp_images = [];
			base("images")
				.select({
					view: "liste_des_images",
					fields: ["Name", "image"],
				})
				.eachPage(
					function page(records, fetchNextPage) {
						records.forEach((item) => {
							tmp_images.push(item);
						});
						fetchNextPage();
					},
					function done(err) {
						if (err) {
							console.error(err);
							return;
						}
						commit("setImages", tmp_images);
					}
				);
		},
		listeOffres({ commit, state }, payload) {
			return new Promise((resolve, reject) => {
				state.offres_ready = false;
				state.last_sync = Date.now();
				state.stats.draft = 0;
				state.stats.send = 0;
				state.stats.accept = 0;
				state.stats.decline = 0;
				state.offres = [];

				const today = new Date();
				const DaysAgo = new Date();
				if(!payload) {
					payload = 7;
				}
				DaysAgo.setDate(today.getDate() - payload);

				let query = {
					view: "listes_des_offres",
					maxRecords: 99,
					pageSize: 99,
					sort: [{ field: "date_modif", direction: "desc" }],
					fields: [
						"id_offre",
						"date_modif",
						"date_cree",
						"date_modifiee",
						"id_commercial",
						"nom_commercial",
						"client",
						"machine",
						"statut",
						"gamme_de_prix_name",
						"page2_intitule",
						"id_clipper",
						"nom_client",
						"machine_name",
					],
				};

				if(state.user.role.administrator) {
					query.maxRecords = 999;
					query.filterByFormula = `AND(IS_AFTER({date_modifiee}, '${DaysAgo.toISOString()}'), IS_BEFORE({date_modifiee}, '${today.toISOString()}'))`;
				} else {
					query.filterByFormula = `AND({id_commercial}='${state.user.ID}', IS_AFTER({date_modifiee}, '${DaysAgo.toISOString()}'), IS_BEFORE({date_modifiee}, '${today.toISOString()}'))`; 
					if(payload && payload < 0) {
						query.maxRecords = 999;
					}
				}
				console.log(query);
				base("offres")
					.select(query)
					.eachPage(
						function page(records, fetchNextPage) {
							records.forEach((item) => {
								if (item.fields.statut == "draft") {
									state.stats.draft += 1;
								} else if (item.fields.statut == "send") {
									state.stats.send += 1;
								} else if (item.fields.statut == "decline") {
									state.stats.decline += 1;
								} else if (item.fields.statut == "accept") {
									state.stats.accept += 1;
								}
								commit("addOffre", item);
							});

							setTimeout(() => {
								fetchNextPage();
							}, 100);
						},
						function done(err) {
							if (err) {
								console.log(err);
								reject(err);
							}
							resolve();
						}
					);
				} 
			);
		},
		listeClients({ commit, state }) {
			state.clients_ready = false;
			var tmp_clients = [];
			if (state.user.role.administrator) {
				base("clients")
					.select({
						view: "listes_clients",
						sort: [{ field: "nom", direction: "asc" }],
					})
					.eachPage(
						function page(records, fetchNextPage) {
							records.forEach((client) => {
								tmp_clients.push(client);
							});
							fetchNextPage();
						},
						function done(err) {
							if (err) {
								return;
							}
							commit("setClients", tmp_clients);
							state.clients_ready = true;
						}
					);
			} else {
				base("clients")
					.select({
						// Selecting the first 3 records in Listes des offres:
						view: "listes_clients",
						sort: [{ field: "nom", direction: "asc" }],
						filterByFormula: "{commercial_attache}=" + state.user.ID,
					})
					.eachPage(
						function page(records, fetchNextPage) {
							records.forEach((client) => {
								tmp_clients.push(client);
							});
							fetchNextPage();
						},
						function done(err) {
							if (err) {
								return;
							}
							commit("setClients", tmp_clients);
							state.clients_ready = true;
						}
					);
			}
		},
		listeEasyroll({ commit }) {
			var tmp_easyroll = [];
			base("easyroll")
				.select({
					// Selecting the first 3 records in Grid view:
					maxRecords: 1,
					view: "Grid view",
				})
				.eachPage(
					function page(records, fetchNextPage) {
						records.forEach((record) => {
							tmp_easyroll.push(record.fields);
						});
						fetchNextPage();
					},
					function done(err) {
						if (err) {
							console.error(err);
							return;
						}
						commit("setEasyroll", tmp_easyroll);
					}
				);
		},
		getCGV({ commit, state }) {
			var temp_CGV = [];
			base("general_conditions")
				.select({
					view: "Grid view",
				})
				.eachPage(
					function page(records, fetchNextPage) {
						records.forEach((record) => {
							state.conditions = record.fields;
						});
						fetchNextPage();
					},
					function done(err) {
						if (err) {
							console.error(err);
							return;
						}
					}
				);
			base("CGV")
				.select({
					view: "cgv",
				})
				.eachPage(
					function page(records, fetchNextPage) {
						records.forEach((record) => {
							temp_CGV.push(record.fields);
						});
						fetchNextPage();
					},
					function done(err) {
						if (err) {
							console.error(err);
							return;
						}
						commit("setCGV", temp_CGV);
					}
				);
		},
		translations({ commit }, payload) {
			if (payload == "fr") {
				commit("setTextes", translations.fr);
			} else if (payload == "de") {
				commit("setTextes", translations.de);
			} else if (payload == "pl") {
				commit("setTextes", translations.pl);
			} else if (payload == "us") {
				commit("setTextes", translations.us);
			} else if (payload == "ro") {
				commit("setTextes", translations.ro);
			} else {
				commit("setTextes", translations.en);
			}
		},
		getOptions({ state }) {
			let tmp_options = {
				options_machines: [],
				options_commandes: [],
				options_accessoires: [],
			};
			base("options_machines_france")
				.select({
					view: "options",
				})
				.eachPage(
					function page(records, fetchNextPage) {
						// This function (`page`) will get called for each page of records.

						records.forEach((item) => {
							tmp_options["options_machines"].push(item);
						});
						fetchNextPage();
					},
					(err) => {
						if (err) {
							console.error(err);
							return;
						}
						base("options_commandes_france")
							.select({
								view: "Grid view",
							})
							.eachPage(
								function page(records, fetchNextPage) {
									// This function (`page`) will get called for each page of records.

									records.forEach((item) => {
										tmp_options["options_commandes"].push(item);
									});
									fetchNextPage();
								},
								(err) => {
									if (err) {
										console.error(err);
										return;
									}
									base("options_accessoires_france")
										.select({
											view: "Grid view",
										})
										.eachPage(
											function page(records, fetchNextPage) {
												// This function (`page`) will get called for each page of records.

												records.forEach((item) => {
													tmp_options["options_accessoires"].push(item);
												});
												fetchNextPage();
											},
											(err) => {
												if (err) {
													console.error(err);
													return;
												}
												state.options = tmp_options;
												state.options_ready = true;
											}
										);
								}
							);
					}
				);
		},
		addLog({ state }) {
			base("logs").create(
				[
					{
						fields: {
							Name: Date.now() + "-" + state.user.ID,
							user_id: state.user.ID,
							user_name: state.user.user_login,
							date_de_connexion: Date.now(),
						},
					},
				],
				(err) => {
					if (err) {
						console.error(err);
						return;
					}
					this.dispatch("getLogs");
				}
			);
		},
		getLogs({ commit, state }) {
			let tmp_logs = [];
			base("logs")
				.select({
					view: "users_log",
					filterByFormula: "{user_id}=" + state.user.ID,
				})
				.eachPage(
					function page(records, fetchNextPage) {
						records.forEach((item) => {
							tmp_logs.push(item);
						});
						fetchNextPage();
					},
					function done() {
						commit("setLogs", tmp_logs);
					}
				);
		},
		updateOptionsCommandes({state}, payload) {
			return new Promise((resolve, reject) => {
				base("offres").update(
					[
						{
							id: state.offre_active.id,
							fields: {
								config_options_commandes: JSON.stringify(payload),
							},
						},
					],
					(err, records) => {
						if (err) {
							reject();
							return;
						}
						resolve(records[0]);
					}
				);
			});	
		},
		updateOptionsAccessoires({state}, payload) {
			return new Promise((resolve, reject) => {
				base("offres").update(
					[
						{
							id: state.offre_active.id,
							fields: {
								config_options_accessoires: JSON.stringify(payload),
							},
						},
					],
					(err, records) => {
						if (err) {
							reject();
							return;
						}
						resolve(records[0]);
					}
				);
			});	
		},
		updateOptionsMachines ({ state }, payload) {
			return new Promise((resolve, reject) => {
				base("offres").update(
					[
						{
							id: state.offre_active.id,
							fields: {
								config_options_machine: JSON.stringify(payload),
							},
						},
					],
					(err, records) => {
						if (err) {
							reject();
							return;
						}
						resolve(records[0]);
					}
				);
			});	
		},
		updateOffres({ commit, state }) {
			state.last_sync = Date.now();
			state.offres_ready = false;
			state.stats.draft = 0;
			state.stats.send = 0;
			state.stats.accept = 0;
			state.stats.decline = 0;
			commit("setFreeze", {
				affichage: true,
				texte: "",
			});
			var tmp_offres = [];
			if (state.user.role.administrator) {
				base("offres")
					.select({
						view: "listes_des_offres",
						maxRecords: 12,
						pageSize: 12,
						fields: [
							"id_offre",
							"date_cree",
							"date_modifiee",
							"id_commercial",
							"nom_commercial",
							"client",
							"machine",
							"statut",
							"gamme_de_prix_name",
							"page2_intitule",
							"id_clipper",
						],
					})
					.eachPage(
						function page(records, fetchNextPage) {
							records.forEach((item) => {
								if (item.fields.statut == "draft") {
									state.stats.draft += 1;
								} else if (item.fields.statut == "send") {
									state.stats.send += 1;
								} else if (item.fields.statut == "decline") {
									state.stats.decline += 1;
								} else if (item.fields.statut == "accept") {
									state.stats.accept += 1;
								}
								if (item.fields.client[0]) {
									base("clients").find(item.fields.client[0], function(err, record) {
										item.fields.client = record.fields;
										base("machines").find(item.fields.machine[0], function(err, record2) {
											item.fields.machine = record2.fields;
											tmp_offres.push(item);
										});
									});
								}
							});

							setTimeout(() => {
								fetchNextPage();
							}, 100);
						},
						function done(err) {
							if (err) {
								return;
							}
							commit("setOffres", tmp_offres);
							state.offres_ready = true;
						}
					);
			} else {
				base("offres")
					.select({
						// Selecting the first 3 records in Listes des offres:
						view: "listes_des_offres",
						maxRecords: 12,
						pageSize: 12,
						fields: [
							"id_offre",
							"date_cree",
							"date_modifiee",
							"id_commercial",
							"nom_commercial",
							"client",
							"machine",
							"statut",
							"gamme_de_prix_name",
							"page2_intitule",
						],
						filterByFormula: "{id_commercial}=" + state.user.ID,
					})
					.eachPage(
						function page(records, fetchNextPage) {
							records.forEach((item) => {
								if (item.fields.statut == "draft") {
									state.stats.draft += 1;
								} else if (item.fields.statut == "send") {
									state.stats.send += 1;
								} else if (item.fields.statut == "decline") {
									state.stats.decline += 1;
								} else if (item.fields.statut == "accept") {
									state.stats.accept += 1;
								}
								if (item.fields.client[0]) {
									base("clients").find(item.fields.client[0], function(err, record) {
										item.fields.client = record.fields;
										base("machines").find(item.fields.machine[0], function(err, record2) {
											item.fields.machine = record2.fields;
											tmp_offres.push(item);
										});
									});
								}
							});
							setTimeout(() => {
								fetchNextPage();
							}, 100);
						},
						function done(err) {
							if (err) {
								return;
							}
							commit("setOffres", tmp_offres);
							state.offres_ready = true;
						}
					);
			}
		},
		getGammePrix({ commit, state }) {
			commit("setFreeze", {
				affichage: true,
				texte: "Get prices Ranges",
			});
			var tmp_ranges = [];
			base("gammes_de_prix")
				.select({
					// Selecting the first 3 records in Grid view:
					view: "Grid view",
				})
				.eachPage(
					function page(records, fetchNextPage) {
						records.forEach((item) => {
							tmp_ranges.push(item);
						});

						fetchNextPage();
					},
					(err) => {
						if (err) {
							console.error(err);
							return;
						}
						state.prices = tmp_ranges;
						commit("setFreeze", {
							affichage: false,
							texte: "",
						});
					}
				);
		},
		getCommerciaux({ state }) {
			Axios({
				url: "https://ambpicot.pro/api/authors/",
				method: "GET",
				params: {
					perpage: 99,
				},
			}).then((res) => {
				state.commerciaux = res.data.result;
				state.commerciaux_ready = true;
			});
		},
		getNewOffre({ commit }, payload) {
			return new Promise((resolve, reject) => {
				base("offres").find(payload, function(err, record) {
					if (err) {
						reject(err);
					}
					commit("addOffre", record);
					resolve(record);
				});
			});
		},
		removeLineOption({ state, commit }, payload) {
			return new Promise((resolve, reject) => {
				state.offre_active.page9_options.splice(payload, 1);
				commit("setFreeze", {
					affichage: true,
					texte: "Removing option. Please wait",
				});
				base("offres").update(
					[
						{
							id: state.offre_active.id,
							fields: {
								page9_options: JSON.stringify(state.offre_active.page9_options),
							},
						},
					],
					(err, records) => {
						commit("setFreeze", {
							affichage: false,
							texte: "",
						});
						if (err) {
							reject();
							return;
						}
						resolve(state.offre_active.page9_options);
					}
				);
			});
		},
		updateTauxDeChangePerso({ state, commit }, payload) {
			return new Promise((resolve, reject) => {
				commit("setFreeze", {
					affichage: true,
					texte: "Updating change rate...",
				});
				base("offres").update(
					[
						{
							id: state.offre_active.id,
							fields: {
								taux_de_change_perso: parseFloat(payload.target.value),
							},
						},
					],
					(err, records) => {
						commit("setFreeze", {
							affichage: false,
							texte: "",
						});
						if (err) {
							reject();
							return;
						}
						state.offre_active.taux_de_change_perso = parseFloat(payload.target.value);
						window.location.reload();
						resolve();
					}
				);
			});
		},
		add_page9_options({ state, commit }, payload) {
			return new Promise((resolve, reject) => {
				state.offre_active.page9_options.push({
					intitule: payload.target[0].value,
					prix: payload.target[1].value,
				});
				commit("setFreeze", {
					affichage: true,
					texte: "Saving options. Please wait",
				});
				base("offres").update(
					[
						{
							id: state.offre_active.id,
							fields: {
								page9_options: JSON.stringify(state.offre_active.page9_options),
							},
						},
					],
					(err, records) => {
						commit("setFreeze", {
							affichage: false,
							texte: "",
						});
						if (err) {
							reject();
							return;
						}
						resolve(state.offre_active.page9_options);
					}
				);
			});
		},
		addOptions({ state, commit }, payload) {
			return new Promise((resolve, reject) => {
				if (!payload) {
					reject();
					return;
				}
				commit("setCostOptions", {
					label: payload.label,
					price: payload.price,
				});

				base("offres").update(
					[
						{
							id: state.offre_active.id,
							fields: {
								extra_options: JSON.stringify(state.offre_active.extra_options_treated),
							},
						},
					],
					(err, records) => {
						if (err) {
							reject();
							return;
						}
						resolve(state.offre_active.extra_options_treated);
					}
				);
			});
		},
		removeExtraOption({ state, dispatch }, payload) {
			state.offre_active.extra_options_treated.splice(payload, 1);
			dispatch("refresh_total");
			base("offres").update(
				[
					{
						id: state.offre_active.id,
						fields: {
							extra_options: JSON.stringify(state.offre_active.extra_options_treated),
						},
					},
				],
				(err, records) => {
					if (err) {
						return;
					}
				}
			);
		},
	},
	getters: {
		options(state) {
			return state.offre_active.page9_options;
		},
		cost_options(state) {
			return state.offre_active.cost_options_treated;
		},
		clients(state) {
			if(state.user.role.administrator) {
				return state.clients;
			} else {
				return state.clients?.filter((client) => client.fields.commercial_attache == state.user.ID) || [];
			}
		},
		offres(state) {
			return state.offres.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
		}
	},
	plugins: [vuexLocal.plugin],
});

<template>
	<div>
		<h1>My Account</h1>
	</div>
</template>
<script>
	export default {
		name: "",
		components: {},
		data() {
			return {};
		},
		methods: {}
	};
</script>

<style lang="less">
</style>